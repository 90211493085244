<template lang="pug">
    .main-wrapper.tipoCheckup-salvar

        Dialog.dialogVisualizarAgendas(header='Agendas selecionadas' :visible.sync='dialogVisualizarAgendas' :modal='true')
            .flex(v-for="item, idx in options.cd_agendas.filter(a => model.cd_agendas.includes(a.id))" :class="{ odd: idx % 2 }")
                .cell-a(v-if="item.ie_tipo_agenda === 'C'")
                    span <b style='font-size:.92em'>Consulta</b><br/>{{ `${ item.cd_especialidades.map(e => e.nm_especialidade).toString() } - ${ item.nm_especialista }` }}
                .cell-a(v-else-if="item.cd_procedimento_estabelecimento")
                    span <b style='font-size:.92em'>Exame</b><br/>{{ `${ item.cd_procedimento_estabelecimento.cd_procedimento.nm_procedimento }` }}
                .cell-a(v-else-if="item.cd_procedimentos.length === 1")
                    span <b style='font-size:.92em'>Exame</b><br/>{{ `${ item.cd_procedimentos[0].ds_procedimento }` }}
                .cell-a(v-else-if="item.cd_procedimentos.length")
                    span <b style='font-size:.92em'>Exames</b><br/>
                    span(v-for='procedimento in item.cd_procedimentos') - {{ procedimento.ds_procedimento }}<br/>

                .cell-b
                    i.jam.jam-trash(style='color:#d00;cursor:pointer' @click="removeAgenda(item)")

        ProgressBar(v-show='waiting' mode="indeterminate")
        div(v-show='! waiting')
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /&nbsp;
                            router-link(:to='{ name: "checkup" }') Checkup
                            span &nbsp;/&nbsp;
                            b {{  model.id ? 'Editar' : 'Adicionar' }}

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-show='waitingForm' mode="indeterminate")
                div(v-show='! waitingForm')
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Tipo de Checkup`")
                        .p-grid.p-fluid

                            .p-col-8.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_tipo_checkup.$error }")
                                label.form-label Tipo de checkup:
                                Dropdown(v-model='$v.model.cd_tipo_checkup.$model' :options='options.cd_tipo_checkup'
                                    optionLabel='ds_checkup' optionValue='id' dataKey='id' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_tipo_checkup.$error')
                                    .form-message--error(v-if="!$v.model.cd_tipo_checkup.required") <b>Tipo de checkup</b> é obrigatório.

                            .p-col-4.p-md-2.ta-center
                                label.form-label Habilitado:
                                InputSwitch(v-model='model.ie_ativo')

                            .p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_desconto_estabelecimento.$error }")
                                label.form-label Desconto do estabelecimento:
                                .p-inputgroup
                                    span.p-inputgroup-addon %
                                    InputText(v-model='$v.model.nr_desconto_estabelecimento.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_desconto_estabelecimento.$error')
                                    .form-message--error(v-if="!$v.model.nr_desconto_estabelecimento.required") <b>Desconto do estabelecimento</b> é obrigatório.
                            
                            .p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_desconto_medclub.$error }")
                                label.form-label Desconto do Medclub:
                                .p-inputgroup
                                    span.p-inputgroup-addon %
                                    InputText(v-model='$v.model.nr_desconto_medclub.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_desconto_medclub.$error')
                                    .form-message--error(v-if="!$v.model.nr_desconto_medclub.required") <b>Desconto do Medclub</b> é obrigatório.

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_estabelecimento.$error }")
                                label.form-label Estabelecimento:
                                Dropdown(v-model='$v.model.cd_estabelecimento.$model' :options='options.cd_estabelecimento' :disabled='waitingAgendas'
                                    optionLabel='nm_fantasia' optionValue='id' dataKey='id' placeholder='Selecione' :filter='true' @input='getAgendas()')
                                .feedback--errors(v-if='submitted && $v.model.cd_estabelecimento.$error')
                                    .form-message--error(v-if="!$v.model.cd_estabelecimento.required") <b>Estabelecimento</b> é obrigatório.
                            
                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_agendas.$error }")
                                label.form-label(style='position:relative')
                                    span Agendas (consulta):
                                    span(v-if='model.cd_agendas.length && ! waitingAgendas' style='position:absolute;bottom:0;right:0;font-size:.92em;cursor:pointer;color:#657786' @click='dialogVisualizarAgendas = true') Ver selecionadas
                                .py-2(v-if='waitingAgendas')
                                    ProgressBar(mode="indeterminate")
                                MultiSelect(v-else-if='options.cd_agendas.length' v-model='$v.model.cd_agendas.$model' :options='options.cd_agendas'
                                    dataKey='id' optionLabel='label' optionValue='id' placeholder='Selecione' :filter='true')
                                .ta-center.mt-2(v-else style='color:#888') (Selecione um estabelecimento)
                                .feedback--errors(v-if='submitted && $v.model.cd_agendas.$error')
                                    .form-message--error(v-if="!$v.model.cd_agendas.required") <b>Agendas</b> é obrigatório.

                            .p-col-12
                                label.form-label Observações:
                                Textarea.textarea-informacoes(v-model='model.ds_observacao' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                            .p-col-12.p-md-6
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                            .p-col-12.p-md-6
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.ta-center
                                Button(icon='jam jam-save' style='max-width:160px' label='Salvar' type="submit")
</template>

<style lang="scss">
    .dialogVisualizarAgendas {
        width: 98%;
        max-width: 640px;
        max-height: 480px;
        .flex {
            display: flex; align-items: center; padding: .2em .6em; background-color: #f8f8f8;
            &.odd { background-color: #e4e4e4; }
        }
        .cell-a { width: 90%; }
        .cell-b { width: 10%; text-align: center; }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Button from 'primevue/button'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import Textarea from 'primevue/textarea'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Dialog from 'primevue/dialog'
    import Dropdown from 'primevue/dropdown'
    import InputSwitch from 'primevue/inputswitch'
    import MultiSelect from 'primevue/multiselect'

    import { required } from 'vuelidate/lib/validators'
    import { Agenda, Checkup, Estabelecimento } from '../../middleware'
    import moment from "moment"

    export default {
        components: { ProgressBar, Button, Panel, InputText, Textarea, DataTable, Column, Dropdown, InputSwitch, MultiSelect, Dialog },
        created () {
            this.waiting = true
            Estabelecimento.findAll().then(response => {
                if (response.status === 200) {
                    this.options.cd_estabelecimento = response.data
                    Checkup.findAllTipoCheckup().then(response => {
                        if (response.status === 200) {
                            this.options.cd_tipo_checkup = response.data
                            let id = 0
                            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                            if (id != 0) {
                                Checkup.find(id).then(response => {
                                    this.waiting = false
                                    if (response.status === 200) {
                                        this.model.id = id
                                        this.model.cd_tipo_checkup = response.data.cd_tipo_checkup.id
                                        this.model.ds_observacao = response.data.ds_observacao
                                        this.model.cd_agendas = response.data.cd_agendas
                                        this.model.ie_ativo = response.data.ie_ativo
                                        this.model.cd_estabelecimento = response.data.cd_estabelecimento.id
                                        this.model.nr_desconto_estabelecimento = parseFloat(response.data.nr_desconto_estabelecimento)
                                        this.model.nr_desconto_medclub = parseFloat(response.data.nr_desconto_medclub)
                                        this.model.cd_agendas = response.data.cd_agendas.map(a => a.id)
                                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                                        this.model.nm_usuario_edi = response.data.nm_usuario_edi
                                        this.model.dt_criado_f = response.data.dt_criado ?
                                            moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                                        this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                            moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : ""
                                        this.getAgendas(true)
                                    }
                                })
                            } else this.waiting = false
                        }
                    })
                }
            })
        },
        data () {
            return {
                model: {
                    cd_tipo_checkup: null,
                    ds_observacao: null,
                    cd_estabelecimento: null,
                    cd_agendas: [],
                    ie_ativo: true,
                    nr_desconto_estabelecimento: null,
                    nr_desconto_medclub: null
                },
                options: {
                    cd_tipo_checkup: [],
                    cd_estabelecimento: [],
                    cd_agendas: []
                },
                waiting: false,
                waitingForm: false,
                waitingAgendas: false,
                submitted: false,
                dialogVisualizarAgendas: false
            }
        },
        validations () {
            let v = {
                model: {
                    cd_tipo_checkup: { required },
                    nr_desconto_estabelecimento: { required },
                    nr_desconto_medclub: { required },
                    cd_estabelecimento: { required },
                    cd_agendas: { required }
                }
            }
            return v
        },
        methods: {
            handleSubmit () {
                
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                if (! this.model.cd_agendas.length) {
                    this.$toast.error('Informe a lista de agendas do checkup', { duration: 3000 })
                    return 0
                }
                
                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                Checkup.save(dataSend).then(response => {
                    this.waitingForm = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        if (! dataSend.id) this.$router.push({ name: 'checkup' })
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    } else Object.values(response.data).forEach(errorMsg => {
                        if (typeof errorMsg == 'object')
                            this.$toast.error(errorMsg[0], { duration: 3000 })
                        else this.$toast.error(errorMsg, { duration: 3000 })
                    })
                })
                
            },
            getAgendas (persist) {
                if (! persist) this.model.cd_agendas = []
                this.options.cd_agendas = []
                this.waitingAgendas = true
                Agenda.findByEstabelecimento(this.model.cd_estabelecimento, { ie_tipo_agenda: 'C' }).then(response => {
                    this.waitingAgendas = false
                    if (response.status === 200) {

                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            if (! persist) this.model.cd_agendas = []
                            this.options.cd_agendas = []
                            response.data.forEach(item => {

                                item.label = `${ item.cd_especialidades.map(e => e.nm_especialidade).toString() } - ${ item.nm_especialista }` 
                                this.options.cd_agendas.push(item)

                            })
                        }
                            
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            removeAgenda (agenda) {
                this.model.cd_agendas = this.model.cd_agendas.filter(a => a != agenda.id)
                if (! this.model.cd_agendas.length) this.dialogVisualizarAgendas = false
            }
        }
    }
</script>